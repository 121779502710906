import React, { Component } from "react"
//import { Router } from "@reach/router"
import Layout from '../components/Layout'


var user = null;
var sessionStorage = null;

function getUser() {


  if (typeof window !== 'undefined') {

    const netlifyIdentity = window.netlifyIdentity;
    //const user;
    if(netlifyIdentity){
      user = netlifyIdentity.currentUser();
      //alert("app: " + user.email)
      //alert(user.user_metadata.full_name)
      //const userName = user.user_metadata.full_name;
      if(user){
        sessionStorage.setItem('savedUser', user.email);
      }
      return user;
    //console.log(user);
    //netlifyIdentity.open();
    }
    else{
      console.log('netlifyIdentity not defined')
      //alert("no window");
    }
  }
}


//function refreshPage() {
//  window.location.reload(false);
//}



class GetNetlifyIdentity extends Component {

  constructor(props) {
   super(props);
   this.state = {
     user: getUser(),
     userStr: "empty"
   };


  }


  componentDidMount() {
    this.setState({user: getUser()});
    //alert("app: " + this.state.user.email);
    //this.state.user = user;
    //this.state.userStr = this.state.user.email;
    //return(<div>ddd</div>)
  }
  componentDidUpdate() {
    this.setState({user: getUser()});
    //alert("app: " + this.state.user.email);
    //this.state.user = user;
    //this.state.userStr = this.state.user.email;
    //return(<div>ddd</div>)
  }

  handleClick() {
    alert("The link was clicked");
  }

  render() {
    if(this.state.user){
      return(<div>{this.state.user.email}</div>)
    }
    else{
      user = getUser();
      //alert("class user: " +user);
      //return (<Redirect to='/app' />)
      if(sessionStorage){
        user = sessionStorage.getItem('savedUser')
      }
      //alert(user)
      return(<div>{user}</div>)
    }
  }
}




const App = () => {

  return (
  <Layout>
  <section className="section section--gradient">
    <div className="container">
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <div className="section">
            <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
              App Page
            </h2>
            <GetNetlifyIdentity />
          </div>
        </div>
      </div>
    </div>
  </section>
  </Layout>

  )
}


export default App
